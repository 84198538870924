import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h1>{`Hi people`}</h1>
    <p>{`Welcome to your new Gatsby site.`}</p>
    <p>{`Now go build something great.`}</p>
    <div style={{
      maxWidth: '300px',
      marginBottom: '1.45rem'
    }}>
  <Image mdxType="Image" />
    </div>
    <Link to="/page-2/" mdxType="Link">Go to page 2</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      